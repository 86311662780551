<template>
    <teleport to="body">
        <div class="modal-container filters">
            <div class="modal-block">
                <div class="modal-title">
                    <div class="title-text">
                        <div>Filters</div> 
                    </div>
                    <div class="title-icons">
                        <div class="title-icon apply" @click="setFilters" v-html="ApplyModal"></div>
                        <div class="title-icon cancel" @click="filtersModel = !filtersModel" v-html="CancelModal"></div>
                    </div>
                </div>
                <div class="modal-body">
                    <component v-if="setFilterComponent && filters" :is="setFilterComponent" :filters="filtersLocal" @setFilterValue="setFilterValue" :groupToFilter="groupToFilter"></component>
                </div>
            </div>
        </div>
    </teleport> 
</template>

<style lang="scss">
.filters-form{
    .filters-row{
        display: flex;
        margin-bottom: 24px;

        .form-column {
            width: 100%;
            overflow: hidden;

            &:first-of-type {
                margin-right: 44px;
            }

            .form-label{
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #444444;
                margin-bottom: 4px;
            }

            .form-inline{
                display: flex;
                >div {
                    margin-right: 4px;
                    width: 100%;
                }
            }
        }
    }
}
</style>

<script>
import { CancelModal, ApplyModal } from '@/utils/icons'
import InputText from '@/components/inputs/InputText'
export default {
    name: 'SetFilters',
    emits: ['update:modelValue', 'setFilters'],
    components: { InputText },
    props: {
        modelValue: {           
            type: Boolean,
            default: () => true,
        },
        setFilterComponent: null,
        filters: {},  
        groupToFilter: { default: () => ({}) , type: Object },
    },

    data() {
        return {
            CancelModal, ApplyModal,
            filtersLocal: {}
        }
    },

    created() {
        this.filtersLocal = {...this.filters}
        document.body.classList.add('overflow-hide')
    },

    beforeUnmount() {
        document.body.classList.remove('overflow-hide')
    },

    computed:{
        filtersModel: {
            get() {
                return this.modelValue
            },

            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },

    methods: {
        setFilterValue({ key, value }) {
            if(value || value === false || value === 0) {
                this.filtersLocal[key] = value
            }
            else
                delete this.filtersLocal[key]

        },

        setFilters() {
            this.$emit('setFilters', this.filtersLocal)
            this.filtersModel = false
        },
    }
}
</script>