import { Filter, Search, Cross } from '@/utils/icons'
import { fetchData /*, fetchCSV*/, urls } from '@/utils/urls.js'

import SetFilters from '@/components/SetFilters.vue'
import HeaderPage from '@/components/HeaderPage.vue'
import Grid from '@/components/Grid.vue'
import InputText from '@/components/inputs/InputText'
import ColapseButton from '@/components/ColapseButton.vue'
import AlertYesNo from '@/components/AlertYesNo.vue'

export const listMixin = {
  components: {
    HeaderPage,
    SetFilters,
    Grid,
    InputText,
    ColapseButton,
    AlertYesNo,
  },
  data() {
    return {
      isVisibleFilters: false,
      isRendered: false,
      Filter,
      Search,
      Cross,
      search_text: {
        placeholder: 'Type here to search',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        //maxlength: 5,
        //minlength: 1,
        class: 'lg',
        leftIcon: Search,
      },
      selectedFilters: [],
      filters: {},
      groupToFilter: {
        //exapmle
        // invite_status_f: {
        //   keyUrl: 'f' + filterKeyUrlIndex++,
        //   key: 'invite_status_f',
        //   name: 'Invite status',
        // },
      },
      rightHeadPosition: null,
      isColapsedBlock: false,
      listTabs: [],
      activeTab: null,
      columns: [],
      listKey: 'list-default',
      selectedRows: [],
      rows: [],
      page: 1,
      per_page: 20,
      newEntity: {},

      // Переменная нужна для того чтобы значение поискового контрола не привязывать к гриду,
      // это для увеличения производительности, чтоб после ввода каждого символа грид не перерисовывал все ячейки.
      search_textToGrid: '',
      countRows: 0,
      isHideNewRowComponent: false,
    }
  },

  watch: {
    isColapsedBlock() {
      this.$nextTick(() => {
        this.setHeightGridScrolableBlock()
      })
    },

    activeTab() {
      this.activateTab(this.activeTab)
      if (this.isRendered) {
        this.clearFilters()
      }
    },

    page() {
      this.fetchData()
    },

    'search_text.value'() {
      if (this.search_text.value?.length > 2 || !this.search_text.value)
        this.fetchData()
    },

    'options.isShowAllColumns'() {
      if (this.options?.isShowAllColumns) {
        this.setDefaultColumnsParams()
      } else {
        this.setMetaDataColumnsParams()
      }
    },
  },

  computed: {
    isLoading() {
      return this.$store.state.isLoading
    },

    page_columns_set() {
      return this.$store.state.metaData?.page_columns_set || []
    },

    isShowClear() {
      if (this.selectedFilters.length) return true
      for (const column of this.columns) {
        if (column.sortType) return true
      }

      return false
    },

    filterGroups() {
      if (!this.selectedFilters.length) return []

      return this.selectedFilters.reduce(function (r, a) {
        r[a.groupKey] = r[a.groupKey] || []
        a.label = ''
        if (a.id.lastIndexOf('_from') > -1) a.label = 'from'
        if (a.id.lastIndexOf('_to') > -1) a.label = 'to'

        r[a.groupKey].push(a)
        return r
      }, Object.create(null))
    },
  },

  mounted() {
    window.addEventListener('scroll', this.setHeadPosition)

    this.$nextTick(() => {
      this.isRendered = true
    })
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.setHeadPosition)
  },

  methods: {
    bodyToList(entity) {
      let body = {
        grid: {
          page: 1,
          per_page: 1,
        },
        filters: {},
        sorting: {},
        common: {},
      }
      if (!entity || !Object.keys(entity).length) return body

      for (const key in entity) {
        if (key in body) body[key] = entity[key]
      }

      return body
    },

    bodyToSave(entity) {
      if (!entity) return {}

      let entityLocal = {}
      for (const key in entity) {
        const element = entity[key]
        if (element && typeof element === 'object' && element?.length) {
          if (typeof element[0] === 'object' && element[0]) {
            entityLocal = {
              ...entityLocal,
              ...{
                [key]: element.map((item) => {
                  return item[Object.keys(item)[0]]
                }),
              },
            }
          } else {
            entityLocal = { ...entityLocal, ...{ [key]: element } }
          }
        } else if (element && typeof element === 'object' && !element?.length) {
          entityLocal = {
            ...entityLocal,
            ...{ [key]: element[Object.keys(element)[0]] },
          }
        } else {
          entityLocal = { ...entityLocal, ...{ [key]: element } }
        }
      }

      return entityLocal
    },

    async saveColumnsParams(entity) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.setColumns,
        'POST',
        entity.columns,
        `list_key=${entity.listKey}`
      )
      if (result?.detail) console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    setQueryParams() {
      let query = {}
      if (this.activeTab) query = { ...query, ...{ tab: this.activeTab } }

      if (this.search_text.value)
        query = { ...query, ...{ search: this.search_text.value } }

      if (Object.keys(this.filters)?.length) {
        let filters = {}
        for (const groupKey in this.groupToFilter) {
          let keyUrl = this.groupToFilter[groupKey].keyUrl
          for (const key in this.filters) {
            if (groupKey === key)
              filters = { ...filters, ...{ [keyUrl]: this.filters[key] } }
          }
        }

        let filtersDecoded = JSON.stringify(filters)
        let buff = new Buffer(filtersDecoded)
        let filtersEncoded = buff.toString('base64')
        query = { ...query, ...{ filters: filtersEncoded } }
      }

      let filteredCols = this.columns
        .filter((item) => item.sortType)
        .map((item) => {
          return { key: item.keyUrl, sortType: item.sortType }
        })
      if (filteredCols.length) {
        let colsDecoded = JSON.stringify(filteredCols)
        let buff = new Buffer(colsDecoded)
        let colsEncoded = buff.toString('base64')
        query = { ...query, ...{ sort: colsEncoded } }
      }

      this.$router.push({ query: query })
    },

    isJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },

    setDataFromQueryParams() {
      // Функция должна вызываться 1 раз перед тем как форма начнет рендерится форма,
      // но после того как выполнятся функции setDefaultColumnsParams, setMetaDataColumnsParams
      //1. tab - активная вкладка
      //2. search - строка поиска
      //3. filters - выбранные фильтры
      //4. sort - выбранные фильтры

      //1
      this.activeTab = this.$route?.query?.tab || this.activeTab

      //2
      this.search_text.value = this.$route?.query?.search || ''

      //3
      let filtersEncoded = this.$route?.query?.filters || ''
      let filtersDecoded = Buffer.from(filtersEncoded, 'base64').toString()
      let filters = null
      if (filtersDecoded && this.isJsonString(filtersDecoded)) {
        filters = JSON.parse(filtersDecoded)
        for (const groupKey in this.groupToFilter) {
          let keyUrl = this.groupToFilter[groupKey].keyUrl
          for (const key in filters) {
            if (keyUrl === key)
              this.filters = {
                ...this.filters,
                ...{ [groupKey]: filters[key] },
              }
          }
        }
        this.setFilters(this.filters)
      }

      //4
      let colsEncoded = this.$route?.query?.sort || ''
      let colsDecoded = Buffer.from(colsEncoded, 'base64').toString()
      let cols = null

      if (colsDecoded && this.isJsonString(colsDecoded)) {
        cols = JSON.parse(colsDecoded)
        cols.forEach((item) => {
          let col = this.columns.find((col) => col.keyUrl === item.key)
          if (col) {
            col.sortType = item.sortType
          }
        })

        this.$store.commit('setIsLoading', false)
        this.fetchData()
      }
    },

    activateTab(id) {
      this.listTabs.forEach((item) => {
        if (item.id === id) item.isActive = true
        else item.isActive = false
      })
    },

    setHeadPosition() {
      this.rightHeadPosition = `-${window.scrollX - 2}px`
    },

    setHeightGridScrolableBlock() {
      let header = document.getElementsByClassName('js-head-and-filters')
      let gridScrolableBlock = document.getElementsByClassName(
        'js-grid-scrolable-block'
      )
      if (!gridScrolableBlock.length) return

      if (!header.length) {
        gridScrolableBlock[0].style = ''
        return
      }

      gridScrolableBlock[0].style = `height: calc(100vh - ${
        header[0].offsetHeight + 73
      }px)`
    },

    isArray(entity) {
      if (typeof entity === 'object' && entity?.length) return true
      return false
    },

    formatDateToDisplay(date) {
      let dateLocal = new Date(date)
      if (dateLocal !== 'Invalid Date' && !isNaN(dateLocal)) {
        let day = dateLocal.getDate()
        let month = dateLocal.getMonth() + 1
        let year = dateLocal.getFullYear()

        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month

        return `${year}-${month}-${day}`
      }
      return date
    },

    setDefaultColumnsParams() {
      this.columns.forEach((item) => {
        item.position = item.positionDefault
        item.isHide = item.isHideDefault
      })

      this.columns.sort((a, b) => a.position - b.position)
    },

    setMetaDataColumnsParams() {
      let { columns } = this.page_columns_set.find(
        (item) => item.listKey === this.listKey
      ) || { columns: [] }
      if (!columns && !columns.length) return

      this.columns.forEach((columnDefault) => {
        let column = columns.find(
          (columnParam) => columnDefault.key === columnParam.key
        )
        if (!column) return

        columnDefault.position = column.position
        columnDefault.isHide = column.isHide
      })

      this.columns.sort((a, b) => a.position - b.position)
    },

    clearFilters() {
      this.selectedFilters = []
      this.filters = {}
      this.search_text.value = null

      for (const column of this.columns) {
        column.sortType = null
      }

      this.fetchData()
    },

    changedColumnsParams(columns) {
      let page_columns_set = this.page_columns_set
      let index = page_columns_set.findIndex(
        (item) => item.listKey === this.listKey
      )
      if (index >= 0) page_columns_set.splice(index, 1)

      let colomnsToSave = columns.map((item) => {
        return {
          key: item.key,
          isHide: item.isHide,
          position: item.position,
        }
      })
      let entiyColumns = { listKey: this.listKey, columns: colomnsToSave }
      page_columns_set.push(entiyColumns)
      let medaData = this.$store.state.metaData
      medaData.page_columns_set = page_columns_set
      this.$store.commit('setMetaData', medaData)

      this.saveColumnsParams(entiyColumns)
    },

    copyText(text) {
      if (!navigator.clipboard) {
        let element = document.createElement('input')
        element.style.position = 'absolute'
        element.style.top = '-999px'
        document.body.appendChild(element)
        element.value = text
        element.select()
        document.execCommand('copy')
        element.remove()
        this.$notify({
          text: 'Link copied',
          type: 'success',
          duration: 1000,
        })
      } else {
        navigator.clipboard.writeText(text).then(
          () => {
            this.$notify({
              text: 'Link copied',
              type: 'success',
              duration: 1000,
            })
          },
          (err) => {
            console.error('Async: Could not copy text: ', err)
          }
        )
      }
    },

    exportSelectedCopyLink(isAllRows) {
      this.exportToCSVRowsCopyLink(isAllRows ? ['all'] : this.selectedRows)
    },

    exportSelected(isAllRows) {
      this.exportToCSVRows(isAllRows ? ['all'] : this.selectedRows)
    },

    setPerPage(value) {
      this.per_page = value
      this.page !== 1 ? (this.page = 1) : this.fetchData()
    },

    saveEntity(entity) {
      this.editRow(entity)
    },

    entityQuery(entity = null) {
      let result = ''

      let entitylocal = entity ? entity : this.newEntity
      for (const key in entitylocal) {
        result += `${key}=${entitylocal[key]}&`
      }

      return result
    },

    nextPage() {
      this.page++
    },

    prevPage() {
      this.page--
    },

    newEntityToDefault() {
      for (let key in this.newEntity) {
        this.newEntity[key] = null
      }
    },

    setNewEntity(entity) {
      for (let key in entity) {
        this.newEntity[key] = entity[key]
      }
    },

    queryToFetch(page = null, per_page = null) {
      let query = `page=${page ? page : this.page}&per_page=${
        per_page ? per_page : this.per_page
      }`

      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      return query
    },

    queryToSort() {
      let searchParams = new URLSearchParams()
      this.columns
        .filter((column) => !column.isHide && column.sortType)
        .forEach((column) => {
          searchParams.append(column.key, column.sortType)
        })

      return searchParams.toString()
    },

    queryToFilters() {
      let searchParams = new URLSearchParams()

      for (const key in this.filters) {
        if (typeof this.filters[key] === 'object') {
          for (const value of this.filters[key]) {
            searchParams.append(key, value)
          }
        } else {
          searchParams.append(key, this.filters[key])
        }
      }

      return searchParams.toString()
    },

    setFilters(filters) {
      this.initNameFilters()

      this.filters = {}
      this.selectedFilters = []
      for (const key in filters) {
        let filter = filters[key]
        this.filters = { ...this.filters, ...{ [key]: filter } }
        let text = filter

        if (
          [
            'date_created_f_from',
            'date_created_f_to',
            'date_published_f_from',
            'date_published_f_to',
          ].includes(key)
        )
          text = this.formatDateToDisplay(text)
        this.selectedFilters.push({
          id: key,
          text: text,
          groupKey: this.groupToFilter[key].key,
          groupName: this.groupToFilter[key].name,
        })
      }
      this.fetchData()
    },

    initNameFilters() {},

    removeFilterGroup(key) {
      this.selectedFilters = this.selectedFilters.filter(
        (item) => item.groupKey !== key
      )
      for (let key in this.filters) {
        let filter = this.selectedFilters.find((item) => item.id === key)
        if (!filter) delete this.filters[key]
      }
      this.fetchData()
    },

    removeFilter(id) {
      const idx = this.selectedFilters.findIndex((item) => item.id === id)
      if (idx > -1) {
        const key = this.selectedFilters[idx].id
        delete this.filters[key]
        this.selectedFilters.splice(idx, 1)
        this.fetchData()
      }
    },

    /*removeFilter(id) {
      const idx = this.selectedFilters.findIndex(item => item.id === id)
      if(idx > -1) this.selectedFilters.splice(idx, 1)
    }*/

    removeFilterArrayItem(id, itemValue) {
      let filter = this.selectedFilters.find((item) => item.id === id)
      if (!filter) return

      let idx = filter.text.findIndex((item) => item === itemValue)
      if (idx > -1) filter.text.splice(idx, 1)

      if (!filter.text.length) {
        this.removeFilter(id)
        return
      }
      this.fetchData()
    },

    deleteSelected(isAllRows) {
      this.deleteRow(isAllRows ? ['all'] : this.selectedRows)
    },
  },
}
